import React, { useState, useEffect } from 'react';
import { useUser } from '../contexts/UserContext';
import { useTranslation } from 'react-i18next';

const HeroSection = ({ onShowSearch }) => {
  const { user, login } = useUser();
  const { t } = useTranslation();
  
  const phrases = [
    t('hero.phrase_1_end'),
    t('hero.phrase_2_end'),
    t('hero.phrase_3_end'),
    t('hero.phrase_4_end'),
    t('hero.phrase_5_end')
  ];

  const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);
  const [fadeIn, setFadeIn] = useState(true);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setFadeIn(false);
      setTimeout(() => {
        setCurrentPhraseIndex((prevIndex) => (prevIndex + 1) % phrases.length);
        setFadeIn(true);
      }, 500); // Half of the transition duration
    }, 3000);

    return () => clearInterval(intervalId);
  }, [phrases]);

  return (
    <div className="bg-white">
      <section className="bg-[#FCF8F1] bg-opacity-30 py-10 sm:py-16 lg:py-24">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="grid items-center grid-cols-1 lg:grid-cols-2 gap-12">
            {/* Single Image */}
            <div className="order-1 lg:order-2">
              <img className="w-full h-auto" src="/banner_img.png" alt="Banner" />
            </div>
            {/* Text Section */}
            <div className="order-2 lg:order-1">
              <h1 className="mt-4 text-4xl font-bold text-black lg:mt-8 sm:text-6xl xl:text-8xl">
                <span>{t('hero.i_want_to')} </span>
                <span
                  className={`transition-opacity duration-1000 ${fadeIn ? 'opacity-100' : 'opacity-0'}`}
                >
                  {phrases[currentPhraseIndex]}
                </span>
              </h1>
              <div className="flex flex-wrap gap-4 mt-8 lg:mt-16">
                <button
                  onClick={() => onShowSearch()}
                  className="inline-flex items-center px-6 py-4 font-semibold text-black transition-all duration-200 bg-[#FEE372] rounded-full hover:bg-[#FEE372] focus:bg-[#FEE372]"
                >
                  {t('hero.get_started')}
                  <svg
                    className="w-6 h-6 ml-8 -mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                      d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </button>
                {!user && (
                  <button
                    onClick={login}
                    className="inline-flex items-center px-6 py-4 font-semibold text-black transition-all duration-200 bg-white border-2 border-black rounded-full hover:bg-gray-100 focus:bg-gray-100"
                  >
                    {t('hero.login')}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HeroSection;