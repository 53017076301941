import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useCMS } from '../contexts/CMSContext';
import TaskModal from './TaskModal';
import { ClipLoader } from 'react-spinners';

const SearchTasks = ({ initialFilters = {} }) => {
  const { t } = useTranslation();
  const cmsContent = useCMS();
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [filters, setFilters] = useState({
    category: '',
    location: '',
    taskType: '',
    familyFriendly: '',
    sortBy: 'relevance',
    ...initialFilters
  });
  const [tasks, setTasks] = useState([]);
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);
  const [error, setError] = useState(null);
  const [userInterests, setUserInterests] = useState([]);

  const categories = cmsContent.interestedCategories || [
    "Administration", "Animal care", "Anything with Food", "Educational",
    "Environmental/ Cultural", "Family Friendly", "Maintenance", "Retail",
    "Social/Care giving", "Sports related", "Other"
  ];

  useEffect(() => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...initialFilters
    }));
    
    const userDataString = localStorage.getItem('user');
    if (userDataString) {
      const userData = JSON.parse(userDataString);
      setUserInterests(userData.interested_categories || []);
    }
  }, [initialFilters]);

  useEffect(() => {
    fetchTasks();
  }, []);

  useEffect(() => {
    filterAndSortTasks();
  }, [searchQuery, filters, tasks, userInterests]);

  const fetchTasks = async () => {
    setIsLoading(true);
    try {
      const response = await fetch('https://joyfromgivingapi-d3hkgkftbth7bcgd.westeurope-01.azurewebsites.net/tasks/tasks');
      if (!response.ok) {
        throw new Error(t('errors.fetch_tasks_failed'));
      }
      const data = await response.json();
      setTasks(data);
    } catch (err) {
      console.error(t('errors.fetch_tasks_failed'), err);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const calculateRelevanceScore = (task) => {
    const taskTags = new Set(task.tags);
    const matchingInterests = userInterests.filter(interest => taskTags.has(interest));
    return matchingInterests.length;
  };

  const filterAndSortTasks = () => {
    const filtered = tasks.filter(task => {
      const matchesSearch = task.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
                            task.description.toLowerCase().includes(searchQuery.toLowerCase());
      const matchesCategory = !filters.category || task.tags.includes(filters.category);
      const matchesLocation = !filters.location || 
                              (filters.location === 'Remote' && task.is_remote) ||
                              (filters.location === 'On-site' && !task.is_remote);
      const matchesTaskType = !filters.taskType || 
                              (filters.taskType === 'One-time' && task.is_one_time) ||
                              (filters.taskType === 'Ongoing' && !task.is_one_time);
      const matchesFamilyFriendly = !filters.familyFriendly || 
                                    (filters.familyFriendly === 'Yes' && task.is_family_friendly) ||
                                    (filters.familyFriendly === 'No' && !task.is_family_friendly);

      return matchesSearch && matchesCategory && matchesLocation && matchesTaskType && matchesFamilyFriendly;
    });

    const sorted = filtered.sort((a, b) => {
      if (filters.sortBy === 'alphabetical') {
        return a.title.localeCompare(b.title);
      } else {
        return calculateRelevanceScore(b) - calculateRelevanceScore(a);
      }
    });

    setFilteredTasks(sorted);
  };

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleLearnMore = (task) => {
    setSelectedTask(task);
  };

  const handleCloseModal = () => {
    setSelectedTask(null);
  };

  return (
    <div className="bg-white py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-3xl font-bold mb-6 text-center text-gray-800">{t('search.title')}</h2>

        <div className="mb-6">
          <input
            type="text"
            className="w-full p-4 rounded-lg shadow-lg bg-gray-100 text-gray-700 focus:outline-none focus:ring-2 focus:ring-yellow-400 border border-gray-300"
            placeholder={t('search.placeholder')}
            value={searchQuery}
            onChange={handleInputChange}
          />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-5 gap-6 mb-8">
          <div>
            <label className="block text-gray-700 font-semibold mb-2">{t('filters.category')}</label>
            <select
              name="category"
              className="w-full p-2 rounded-lg border border-gray-300 appearance-none focus:outline-none focus:ring-2 focus:ring-yellow-400"
              style={{
                appearance: 'none',
                WebkitAppearance: 'none',
                MozAppearance: 'none'
              }}
              value={filters.category}
              onChange={handleFilterChange}
            >
              <option value="">{t('filters.all_categories')}</option>
              {categories.map((category, index) => (
                <option key={index} value={category}>{category}</option>
              ))}
            </select>
          </div>

          <div>
            <label className="block text-gray-700 font-semibold mb-2">{t('filters.location')}</label>
            <select
              name="location"
              className="w-full p-2 rounded-lg border border-gray-300 appearance-none focus:outline-none focus:ring-2 focus:ring-yellow-400"
              style={{
                appearance: 'none',
                WebkitAppearance: 'none',
                MozAppearance: 'none'
              }}
              value={filters.location}
              onChange={handleFilterChange}
            >
              <option value="">{t('filters.all_locations')}</option>
              <option value="Remote">{t('filters.remote')}</option>
              <option value="On-site">{t('filters.on_site')}</option>
            </select>
          </div>

          <div>
            <label className="block text-gray-700 font-semibold mb-2">{t('filters.task_type')}</label>
            <select
              name="taskType"
              className="w-full p-2 rounded-lg border border-gray-300 appearance-none focus:outline-none focus:ring-2 focus:ring-yellow-400"
              style={{
                appearance: 'none',
                WebkitAppearance: 'none',
                MozAppearance: 'none'
              }}
              value={filters.taskType}
              onChange={handleFilterChange}
            >
              <option value="">{t('filters.all_types')}</option>
              <option value="One-time">{t('filters.one_time')}</option>
              <option value="Ongoing">{t('filters.ongoing')}</option>
            </select>
          </div>

          <div>
            <label className="block text-gray-700 font-semibold mb-2">{t('filters.family_friendly')}</label>
            <select
              name="familyFriendly"
              className="w-full p-2 rounded-lg border border-gray-300 appearance-none focus:outline-none focus:ring-2 focus:ring-yellow-400"
              style={{
                appearance: 'none',
                WebkitAppearance: 'none',
                MozAppearance: 'none'
              }}
              value={filters.familyFriendly}
              onChange={handleFilterChange}
            >
              <option value="">{t('filters.all')}</option>
              <option value="Yes">{t('filters.yes')}</option>
              <option value="No">{t('filters.no')}</option>
            </select>
          </div>

          <div>
            <label className="block text-gray-700 font-semibold mb-2">{t('filters.sort_by')}</label>
            <select
              name="sortBy"
              className="w-full p-2 rounded-lg border border-gray-300 appearance-none focus:outline-none focus:ring-2 focus:ring-yellow-400"
              style={{
                appearance: 'none',
                WebkitAppearance: 'none',
                MozAppearance: 'none'
              }}
              value={filters.sortBy}
              onChange={handleFilterChange}
            >
              <option value="relevance">{t('filters.relevance')}</option>
              <option value="alphabetical">{t('filters.alphabetical')}</option>
            </select>
          </div>
        </div>

        {error && (
          <div className="text-red-500 text-center mb-4">{error}</div>
        )}

        {isLoading ? (
          <div className="flex justify-center items-center min-h-[400px]">
            <ClipLoader
              size={50}
              color={"#FEE372"}
              loading={isLoading}
              aria-label="Loading Spinner"
            />
          </div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {filteredTasks.length > 0 ? (
              filteredTasks.map((task) => (
                <div key={task.id} className="bg-blue-100 rounded-lg shadow-md overflow-hidden">
                  <img
                    src={task.thumbnail_url || '/default-thumbnail.jpg'}
                    alt={task.title}
                    className="w-full h-40 object-cover"
                  />
                  <div className="p-4">
                    <h3 className="text-lg font-bold text-gray-800 mb-2 truncate">{task.title}</h3>
                    <p className="text-sm text-gray-600 mb-1">
                      <strong>{t('filters.location')}:</strong> {task.is_remote ? t('filters.remote') : task.location}
                    </p>
                    <p className="text-sm text-gray-600 mb-1">
                      <strong>{t('filters.task_type')}:</strong> {task.is_one_time ? t('filters.one_time') : t('filters.ongoing')}
                    </p>
                    <p className="text-sm text-gray-600 mb-1">
                      <strong>{t('filters.family_friendly')}:</strong> {task.is_family_friendly ? t('filters.yes') : t('filters.no')}
                    </p>
                    <p className="text-sm text-gray-600 mb-3 line-clamp-3">{task.description}</p>
                    <button
                      className="w-full bg-[#FEE372] text-black px-4 py-2 rounded-full hover:bg-[#FEE372]/80 transition-colors duration-200 text-sm"
                      onClick={() => handleLearnMore(task)}
                    >
                      {t('search.learn_more')}
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <div className="col-span-full text-center text-gray-500 py-8">
                {t('search.no_results')}
              </div>
            )}
          </div>
        )}

        {selectedTask && (
          <TaskModal 
            task={selectedTask} 
            onClose={handleCloseModal} 
            refreshTasks={fetchTasks} 
          />
        )}
      </div>
    </div>
  );
};

export default SearchTasks;