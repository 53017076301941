import React, { createContext, useState, useEffect, useContext } from 'react';
import { PublicClientApplication } from '@azure/msal-browser';
import msalConfig, { loginRequest } from '../authConfig';
import ProfileModal from '../components/ProfileModal'; // Make sure this is correctly imported

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const [msalInstance, setMsalInstance] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false); // Modal visibility state

  // Initialize MSAL instance
  useEffect(() => {
    const initializeMsal = async () => {
      const msalInstance = new PublicClientApplication(msalConfig);
      await msalInstance.initialize();
      setMsalInstance(msalInstance);

      const accounts = msalInstance.getAllAccounts();
      if (accounts.length > 0) {
        msalInstance.setActiveAccount(accounts[0]); // Set the active account if it exists
        setUser(accounts[0]);
      }
      setIsInitialized(true);
    };

    initializeMsal();
  }, []);

  // Function to get access token
  const getAccessToken = async () => {
    if (!isInitialized) throw new Error('MSAL is not initialized');

    try {
      const activeAccount = msalInstance.getActiveAccount();
      if (!activeAccount) throw new Error('No active account! Please log in.');

      const silentRequest = {
        ...loginRequest,
        account: activeAccount,
      };
      const silentResponse = await msalInstance.acquireTokenSilent(silentRequest);
      return silentResponse.accessToken;
    } catch (error) {
      console.error('Silent token acquisition failed:', error);
      if (error.name === 'InteractionRequiredAuthError') {
        await msalInstance.acquireTokenRedirect(loginRequest);
      } else {
        throw error;
      }
    }
  };

  // Function to check if user exists and register if needed
  const checkAndRegisterUser = async (authToken, objectId, firstName, lastName, email) => {
    try {
      const response = await fetch('https://joyfromgivingapi-d3hkgkftbth7bcgd.westeurope-01.azurewebsites.net/auth/login/', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ object_id: objectId }),
      });

      if (response.status === 404) {
        // User doesn't exist, register them
        const registrationData = {
          object_id: objectId,
          first_name: firstName,
          last_name: lastName,
          email: email,
        };
        const registerResponse = await fetch('https://joyfromgivingapi-d3hkgkftbth7bcgd.westeurope-01.azurewebsites.net/auth/register/', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(registrationData),
        });

        if (!registerResponse.ok) throw new Error('Registration failed');
      } else if (!response.ok) {
        throw new Error('Login failed');
      } else {
        const data = await response.json();
        return data;
      }
    } catch (error) {
      console.error('Error checking or registering user:', error);
      throw error;
    }
  };

  // Function to handle user login
  const login = async () => {
    if (!isInitialized) return;

    try {
      const response = await msalInstance.loginPopup(loginRequest);
      msalInstance.setActiveAccount(response.account); // Set the active account after login
      setUser(response.account);

      const authToken = await getAccessToken(); // Get access token after login
      const objectId = response.idTokenClaims.sub;
      const firstName = response.idTokenClaims.given_name;
      const lastName = response.idTokenClaims.family_name;
      const email = response.account.username;

      const userData = await checkAndRegisterUser(authToken, objectId, firstName, lastName, email);

      const {
        user_id,
        impact,
        values,
        environments,
        interests,
        skills,
        rating,
        self_description,
        address,
        date_of_birth,
        contact_number,
        prof_pic_path,
        profile_complete,
        interested_categories
      } = userData;

      console.log(userData)
      // Store user data in localStorage
      localStorage.setItem('authToken', authToken);
      localStorage.setItem('user', JSON.stringify({
        userId: user_id,
        objectId,
        email,
        first_name: firstName,
        last_name: lastName,
        impact,
        values,
        environments,
        interests,
        skills,
        rating,
        self_description,
        address,
        date_of_birth,
        contact_number,
        prof_pic_path,
        profile_complete,
        interested_categories
      }));

      setUser({
        authToken,
        userId: user_id,
        objectId,
        email,
        first_name: firstName,
        last_name: lastName,
        impact,
        values,
        environments,
        interests,
        skills,
        rating,
        self_description,
        address,
        date_of_birth,
        contact_number,
        prof_pic_path,
        profile_complete,
        interested_categories
      });

      setAvatar(prof_pic_path);

      // Trigger modal after login
      setShowProfileModal(true);

      // Update last login timestamp
      try {
        await fetch(`https://joyfromgivingapi-d3hkgkftbth7bcgd.westeurope-01.azurewebsites.net/users/user/${email}/update_last_login`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        });
      } catch (error) {
        console.error('Failed to update last login:', error);
      }
    } catch (error) {
      console.error('Login failed:', error);
    }
  };

  // Function to handle profile save
  const handleProfileSave = async (updatedProfile) => {
    const formData = new FormData();
    formData.append('first_name', updatedProfile.first_name || '');
    formData.append('last_name', updatedProfile.last_name || '');
    formData.append('contact_number', updatedProfile.contact_number || '');
    formData.append('home_address', updatedProfile.address || '');
    formData.append('self_description', updatedProfile.self_description || '');
  
    // Ensure we handle the interested categories safely (comma-separated)
    if (updatedProfile.interested_categories && updatedProfile.interested_categories.length > 0) {
      formData.append('interested_categories', updatedProfile.interested_categories.join(','));
    }
  
    // Append the avatar file if it exists
    if (updatedProfile.avatar) {
      formData.append('avatar', updatedProfile.avatar);
    }
  
    try {
      const response = await fetch(`https://joyfromgivingapi-d3hkgkftbth7bcgd.westeurope-01.azurewebsites.net/users/${updatedProfile.email}/update`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${updatedProfile.authToken}`,
        },
        body: formData,
      });
  
      if (!response.ok) {
        throw new Error('Failed to update profile');
      }
  
      const result = await response.json();
      // Handle success (e.g., close modal, update user state)
    } catch (error) {
      console.error('Failed to update profile:', error);
    }
  };
  

  // Function to handle logout
  const logout = async () => {
    if (!isInitialized) return;

    try {
      await msalInstance.logoutPopup({
        postLogoutRedirectUri: window.location.origin,
        mainWindowRedirectUri: window.location.origin,
      });
      localStorage.clear();
      setUser(null);
      setAvatar(null);
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  if (!isInitialized) {
    return <div>Initializing...</div>; // Or loading spinner
  }

  return (
    <UserContext.Provider value={{ user, avatar, login, logout, isInitialized }}>
      {children}
      {showProfileModal && (
        <ProfileModal
          user={user}
          onClose={() => setShowProfileModal(false)}
          onSave={handleProfileSave}
        />
      )}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
