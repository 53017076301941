import React, { useState, useEffect } from 'react';
import { useUser } from '../contexts/UserContext';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { useTranslation } from 'react-i18next';
import { useFetchWithAuth } from '../useFetchWithAuth';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Fix for default marker icon issue in react-leaflet
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const TaskModal = ({ task, onClose, refreshTasks }) => {
  const { user, login } = useUser();
  const { fetchWithAuth } = useFetchWithAuth();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState('');
  const [userHasEnrolled, setUserHasEnrolled] = useState(false);
  const [mapCenter, setMapCenter] = useState(null);

  const isAuthenticated = user && user.authToken !== null;

  useEffect(() => {
    if (task.geo_location && task.geo_location.lat && task.geo_location.lng) {
      setMapCenter({
        lat: parseFloat(task.geo_location.lat),
        lng: parseFloat(task.geo_location.lng)
      });
    }

    if (user && user.userId) {
      const currentUserId = user.userId;
      const enrolledInGiverApplications = task.giver_applications?.some(appId => appId === currentUserId);
      const enrolledInAcceptedGivers = task.accepted_givers?.some(giverId => giverId === currentUserId);
      setUserHasEnrolled(enrolledInGiverApplications || enrolledInAcceptedGivers);
    }
  }, [task, user]);

  const handleEnroll = async () => {
    if (!user) return;

    const userProfile = {
      id: user.userId,
      first_name: user.first_name,
      last_name: user.last_name,
      object_id: user.objectId,
      contact_number: user.contact_number,
      rating: 5,
      prof_pic_path: user.prof_pic_path,
    };

    setIsLoading(true);
    try {
      const response = await fetchWithAuth(
        `https://joyfromgivingapi-d3hkgkftbth7bcgd.westeurope-01.azurewebsites.net/tasks/tasks/${task.id}/add_giver`, 
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(userProfile),
        }
      );

      if (!response.ok) throw new Error(t('task_modal.errors.enroll_failed'));
      await refreshTasks();
      setIsSuccess(true);
    } catch (error) {
      setError(error.message);
      console.error(t('task_modal.errors.enroll_error'), error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleBackOut = async () => {
    if (!user) return;

    setIsLoading(true);
    try {
      const response = await fetchWithAuth(
        `https://joyfromgivingapi-d3hkgkftbth7bcgd.westeurope-01.azurewebsites.net/tasks/tasks/${task.id}/remove_giver`, 
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (!response.ok) throw new Error(t('task_modal.errors.back_out_failed'));
      await refreshTasks();
      setIsSuccess(true);

      setTimeout(() => {
        onClose();
      }, 1500);
    } catch (error) {
      setError(error.message);
      console.error(t('task_modal.errors.back_out_error'), error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSignIn = () => {
    login();
  };

  if (!task) return null;

  const { title, description, location, organisation_details, tags, is_family_friendly } = task;

  return (
    <div className="fixed inset-0 z-50 bg-white overflow-y-auto">
      <div className="container mx-auto px-4 py-8">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-4xl font-bold text-gray-900">{title}</h2>
          <button
            onClick={onClose}
            className="bg-[#FEE372] text-black px-6 py-3 rounded-full hover:bg-[#FEE372] transition-colors duration-200"
          >
            {t('task_modal.close')}
          </button>
        </div>

        <div className="flex flex-col lg:flex-row lg:space-x-8">
          <div className="lg:w-2/3">
            <div className="mb-8">
              <h3 className="font-semibold text-2xl text-gray-800 mb-3">{t('task_modal.description')}</h3>
              <p className="text-gray-700 leading-relaxed text-lg">{description}</p>
            </div>

            <div className="mb-8">
              <h3 className="font-semibold text-2xl text-gray-800 mb-3">{t('task_modal.location')}</h3>
              <p className="text-gray-600 mb-4 text-lg">{location}</p>
              {mapCenter ? (
                <div className="rounded-lg overflow-hidden h-[400px]">
                  <MapContainer 
                    center={[mapCenter.lat, mapCenter.lng]} 
                    zoom={14} 
                    style={{ height: '100%', width: '100%' }}
                    scrollWheelZoom={false}
                  >
                    <TileLayer
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    />
                    <Marker position={[mapCenter.lat, mapCenter.lng]}>
                      <Popup>
                        <div className="text-sm">
                          <strong>{title}</strong>
                          <br />
                          {location}
                        </div>
                      </Popup>
                    </Marker>
                  </MapContainer>
                </div>
              ) : (
                <p className="text-gray-600 italic">Map not available for this location.</p>
              )}
            </div>

            {organisation_details && (
              <div className="mb-8">
                <h3 className="font-semibold text-2xl text-gray-800 mb-3">{t('task_modal.organization_info')}</h3>
                <div className="border-l-4 border-yellow-400 pl-4">
                  <p className="text-gray-700 text-lg mb-2">
                    <span className="font-bold">{t('task_modal.org_name')}: </span>
                    {organisation_details.organisation_name}
                  </p>
                  <p className="text-gray-700 text-lg mb-2">
                    <span className="font-bold">{t('task_modal.org_description')}: </span>
                    {organisation_details.organisation_description}
                  </p>
                  <p className="text-gray-700 text-lg mb-2">
                    <span className="font-bold">{t('task_modal.org_contact')}: </span>
                    {organisation_details.organisation_contact}
                  </p>
                  <p className="text-gray-700 text-lg mb-2">
                    <span className="font-bold">{t('task_modal.org_email')}: </span>
                    {organisation_details.organisation_email}
                  </p>
                  <p className="text-gray-700 text-lg">
                    <span className="font-bold">{t('task_modal.org_location')}: </span>
                    {organisation_details.organisation_location}
                  </p>
                </div>
              </div>
            )}
          </div>

          <div className="lg:w-1/3">
            <div className="bg-gray-100 p-6 rounded-lg shadow-md">
              <div className="mb-6">
                <h3 className="font-semibold text-2xl text-gray-800 mb-2">{t('filters.family_friendly')}</h3>
                <p className="text-gray-600 text-lg">
                  {is_family_friendly ? t('filters.yes') : t('filters.no')}
                </p>
              </div>

              <div className="mb-6">
                <h3 className="font-semibold text-2xl text-gray-800 mb-2">{t('task_modal.tags')}</h3>
                <div className="flex flex-wrap gap-2">
                  {tags && tags.length > 0 ? (
                    tags.map((tag, index) => (
                      <span key={index} className="bg-[#FEE372] text-sm px-3 py-1 rounded-full">
                        {tag}
                      </span>
                    ))
                  ) : (
                    <p className="text-gray-600 text-lg">{t('task_modal.no_tags')}</p>
                  )}
                </div>
              </div>

              <div className="mb-6">
                {userHasEnrolled ? (
                  <div className="flex flex-col space-y-4">
                    <div className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4" role="alert">
                      <p className="font-bold">{t('task_modal.enrolled')}</p>
                      <p>{t('task_modal.enrolled_desc')}</p>
                    </div>
                    <button
                      onClick={handleBackOut}
                      disabled={isLoading}
                      className="bg-red-400 text-white px-6 py-3 rounded-full hover:bg-red-500 transition-colors duration-200 disabled:opacity-50 w-full"
                    >
                      {isLoading ? t('task_modal.backing_out') : t('task_modal.back_out')}
                    </button>
                  </div>
                ) : !isSuccess ? (
                  <>
                    {error && (
                      <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4" role="alert">
                        <p className="font-bold">{t('task_modal.error')}</p>
                        <p>{error}</p>
                      </div>
                    )}
                    {isAuthenticated ? (
                      <button
                        onClick={handleEnroll}
                        disabled={isLoading}
                        className="bg-[#FEE372] text-black px-6 py-3 rounded-full hover:bg-[#FEE372] transition-colors duration-200 disabled:opacity-50 w-full"
                      >
                        {isLoading ? t('task_modal.enrolling') : t('task_modal.count_me_in')}
                      </button>
                    ) : (
                      <button
                        onClick={handleSignIn}
                        className="text-gray-800 px-6 py-3 rounded-full hover:bg-blue-100 transition-colors duration-200 w-full"
                        style={{ backgroundColor: 'rgb(212, 229, 251)' }}
                      >
                        {t('task_modal.sign_in_to_participate')}
                      </button>
                    )}
                  </>
                ) : (
                  <div className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4" role="alert">
                    <p className="font-bold">{t('task_modal.success')}</p>
                    <p>{t('task_modal.request_noted')}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskModal;