// useAuthToken.js
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";

export const useAuthToken = () => {
    const { instance, accounts } = useMsal();

    const getToken = async () => {
        const request = {
            scopes: ["openid", "profile", 'https://joyfromgiving.onmicrosoft.com/4be1a1f2-ca23-4881-8e67-ab685c96ad25/access_as_user'],
            account: accounts[0],
        };

        try {
            const tokenResponse = await instance.acquireTokenSilent(request);
            return tokenResponse.accessToken;
        } catch (error) {
            if (error instanceof InteractionRequiredAuthError) {
                // Fallback to interactive login if silent acquisition fails
                await instance.acquireTokenRedirect(request);
            } else {
                console.error('Token acquisition error:', error);
                throw error;
            }
        }
    };

    return { getToken };
};
