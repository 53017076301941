// useFetchWithAuth.js
import { useAuthToken } from './useAuthToken';

export const useFetchWithAuth = () => {
    const { getToken } = useAuthToken();

    const fetchWithAuth = async (url, options = {}) => {
        try {
            const token = await getToken(); // Get the access token

            const headers = {
                ...options.headers,
                'Authorization': `Bearer ${token}`,
            };

            const response = await fetch(url, {
                ...options,
                headers,
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            return response;
        } catch (error) {
            console.error('Fetch request failed:', error);
            throw error;
        }
    };

    return { fetchWithAuth };
};
