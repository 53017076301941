const msalConfig = {
    auth: {
        clientId: '3d65f1d9-0986-4003-8aa6-09105b6c69d8', // Application (client) ID
        authority: 'https://joyfromgiving.b2clogin.com/joyfromgiving.onmicrosoft.com/B2C_1_signupsignin', // Authority URL including the policy
        knownAuthorities: ['joyfromgiving.b2clogin.com'], // Tenant-specific domain
        // redirectUri: 'https://joyfromgivinggivers-f9cqbza5ddgzchek.westeurope-01.azurewebsites.net', // The URL where the user will be redirected after authentication
    },
    cache: {
        cacheLocation: 'localStorage', // Storage location for tokens
        storeAuthStateInCookie: false, // Store auth state in cookies if you have issues with cookies or IE11/Edge
    },
};

export const loginRequest = {
    scopes: ["openid", "profile", 'https://joyfromgiving.onmicrosoft.com/4be1a1f2-ca23-4881-8e67-ab685c96ad25/access_as_user'],
};

export default msalConfig;
