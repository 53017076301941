import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const TrustUsSection = () => {
  const { t } = useTranslation();
  const [organizations, setOrganizations] = useState([]);
  const [showAllModal, setShowAllModal] = useState(false);
  const limitedDisplayCount = 15; // Number of partners to display initially

  useEffect(() => {
    const fetchOrganizations = async () => {
      try {
        const response = await fetch('https://joyfromgivingapi-d3hkgkftbth7bcgd.westeurope-01.azurewebsites.net/manual-orgs/manual_org_names');
        if (!response.ok) {
          throw new Error('Failed to fetch organizations');
        }
        const data = await response.json();
        const shuffled = data.sort(() => 0.5 - Math.random());
        setOrganizations(shuffled);
      } catch (error) {
        console.error('Error fetching organizations:', error);
      }
    };
    fetchOrganizations();
  }, []);

  const getRandomSize = () => {
    const sizes = ['text-sm', 'text-base', 'text-lg', 'text-xl'];
    return sizes[Math.floor(Math.random() * sizes.length)];
  };

  const renderPartners = (partners, limit = partners.length) => (
    <div className="flex flex-wrap justify-center items-center">
      {partners.slice(0, limit).map((org, index) => (
        <div 
          key={index} 
          className={`m-2 p-2 ${getRandomSize()} font-bold text-blue-800 bg-white bg-opacity-50 rounded transition-all duration-300 ease-in-out hover:scale-110`}
        >
          {org}
        </div>
      ))}
    </div>
  );

  return (
    <div className="py-16" style={{ backgroundColor: '#D4E5FB' }}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Three Feature Cards */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
          {/* Feature 1 */}
          <div className="text-center">
            <div className="w-16 h-16 mx-auto rounded-full mb-4 flex items-center justify-center" style={{ backgroundColor: '#FFECB3' }}>
              <span className="text-2xl text-blue-500">🙌</span>
            </div>
            <h3 className="font-bold text-lg">{t('trust.serious_organisations')}</h3>
            <p className="text-gray-500 text-sm">{t('trust.serious_organisations_desc')}</p>
          </div>
          {/* Feature 2 */}
          <div className="text-center">
            <div className="w-16 h-16 mx-auto rounded-full mb-4 flex items-center justify-center" style={{ backgroundColor: '#FFECB3' }}>
              <span className="text-2xl text-blue-500">🔒</span>
            </div>
            <h3 className="font-bold text-lg">{t('trust.protecting_confidentiality')}</h3>
            <p className="text-gray-500 text-sm">{t('trust.protecting_confidentiality_desc')}</p>
          </div>
          {/* Feature 3 */}
          <div className="text-center">
            <div className="w-16 h-16 mx-auto rounded-full mb-4 flex items-center justify-center" style={{ backgroundColor: '#FFECB3' }}>
              <span className="text-2xl text-blue-500">🚀</span>
            </div>
            <h3 className="font-bold text-lg">{t('trust.memorable_experience')}</h3>
            <p className="text-gray-500 text-sm">{t('trust.memorable_experience_desc')}</p>
          </div>
        </div>

        <div className="mt-12">
          <h3 className="text-2xl font-bold text-center mb-6">{t('trust.trusted_partners')}</h3>
          <div className="rounded-lg p-6">
            {renderPartners(organizations, limitedDisplayCount)}
          </div>
          {organizations.length > limitedDisplayCount && (
            <div className="text-center mt-8">
              <h4 className="text-l font-bold mb-2">{t('trust.and_many_more')}</h4>
            </div>
          )}
        </div>  
      </div>

      {/* Modal for showing all partners */}
      {showAllModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="rounded-lg p-6 max-w-3xl w-full max-h-[80vh] overflow-y-auto" style={{ backgroundColor: '#D4E5FB' }}>
            <h2 className="text-2xl font-bold mb-4 text-blue-800">{t('trust.all_partners')}</h2>
            {renderPartners(organizations)}
            <button
              onClick={() => setShowAllModal(false)}
              className="mt-6 bg-[#FEE372] text-blue-800 px-4 py-2 rounded hover:bg-[#FEE372] transition-colors font-bold"
            >
              {t('trust.close')}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TrustUsSection;