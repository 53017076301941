import React, { useState, useEffect } from 'react';
import { useUser } from '../contexts/UserContext';
import { useTranslation } from 'react-i18next';
import logo from './logo.png';

function Header({ onShowSearch, onShowSuggestedTasks, onShowActiveTasks, onShowHome }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { user, login, logout, isInitialized } = useUser();
  const { t } = useTranslation();
  const [firstName, setFirstName] = useState('');

  useEffect(() => {
    // const storedUser = JSON.parse(localStorage.getItem('user'));
    // // console.log(storedUser.first_name)
    // if (storedUser && storedUser.first_name) {
    //   // console.log(storedUser)
    //   setFirstName('');

    // }
    // console.log(firstName)
    setFirstName('');
  }, []);

  const handleNavClick = (action) => {
    setIsMenuOpen(false);
    switch(action) {
      case 'find-mission':
        onShowSearch();
        break;
      case 'suggested-tasks':
        onShowSuggestedTasks();
        break;
      case 'active-tasks':
        onShowActiveTasks();
        break;
      case 'home':
        onShowHome();
        break;
    }
  };

  if (!isInitialized) {
    return <div>Loading...</div>;
  }

  return (
    <header className="bg-[#FCF8F1] bg-opacity-30">
      <div className="px-4 mx-auto sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16 lg:h-20">
          <div className="flex-shrink-0">
            <a href="#" title="" className="flex" onClick={() => handleNavClick('home')}>
              <img className="w-auto h-16" src={logo} alt="Joy from giving logo" />
            </a>
          </div>

          <div className="flex items-center lg:hidden">
            <button
              type="button"
              className="inline-flex p-2 text-black transition-all duration-200 rounded-md hover:text-gray-600"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
              </svg>
            </button>
          </div>

          <nav className={`${isMenuOpen ? 'fixed inset-0 z-50 bg-white' : 'hidden'} lg:relative lg:bg-transparent lg:flex lg:items-center lg:justify-center lg:w-auto`}>
            {isMenuOpen && (
              <button
                className="absolute top-4 right-4 p-2 text-black"
                onClick={() => setIsMenuOpen(false)}
              >
                <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                </svg>
              </button>
            )}
            <div className="h-full flex flex-col justify-center items-center space-y-8 lg:space-y-0 lg:flex-row lg:space-x-6">
              {user && firstName && (
                <span className="text-black font-semibold">
                  {t('header.hello', { name: firstName })}
                </span>
              )}
              <button onClick={() => handleNavClick('home')} className="w-full lg:w-auto inline-flex items-center justify-center px-4 py-2 text-xl lg:text-base font-semibold text-black transition-all duration-200 hover:text-gray-600">
                <span className="mr-2">🏠</span>{t('header.home')}
              </button>
              <button onClick={() => handleNavClick('find-mission')} className="w-full lg:w-auto inline-flex items-center justify-center px-4 py-2 text-xl lg:text-base font-semibold text-black transition-all duration-200 hover:text-gray-600">
                <span className="mr-2">🔍</span>{t('header.find_a_mission')}
              </button>
              {user && (
                <>
                  <button onClick={() => handleNavClick('suggested-tasks')} className="w-full lg:w-auto inline-flex items-center justify-center px-4 py-2 text-xl lg:text-base font-semibold text-black transition-all duration-200 hover:text-gray-600">
                    <span className="mr-2">💡</span>{t('header.suggested_tasks')}
                  </button>
                  <button onClick={() => handleNavClick('active-tasks')} className="w-full lg:w-auto inline-flex items-center justify-center px-4 py-2 text-xl lg:text-base font-semibold text-black transition-all duration-200 hover:text-gray-600">
                    <span className="mr-2">📋</span>{t('header.active_tasks')}
                  </button>
                </>
              )}
              {user ? (
                <button onClick={logout} className="w-full lg:w-auto inline-flex items-center justify-center px-4 py-2 text-xl lg:text-base font-semibold text-black transition-all duration-200 hover:text-gray-600">
                  <span className="mr-2">👤</span>{t('header.logout')}
                </button>
              ) : (
                <button onClick={login} className="w-full lg:w-auto inline-flex items-center justify-center px-4 py-2 text-xl lg:text-base font-semibold text-black transition-all duration-200 hover:text-gray-600">
                  <span className="mr-2">👤</span>{t('header.login')}
                </button>
              )}
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
}

export default Header;