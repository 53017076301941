import React, { useState, useEffect } from 'react';
import { UserProvider } from './contexts/UserContext';
import { CMSProvider } from './contexts/CMSContext';
import { LoadScript } from '@react-google-maps/api';
import './i18n';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import OneTimeOrOngoingChoice from './components/OneTimeOrOngoingChoice';
import FamilyFriendlyChoice from './components/FamilyFriendlyChoice';
import Categories from './components/Categories';
import SearchTasks from './components/SearchTasks';
import SuggestedTasks from './components/SuggestedTasks';
import ActiveTasks from './components/ActiveTasks';
import Footer from './components/Footer';
import FAQSection from './components/FAQSection';
import TestimonialsSection from './components/TestimonialsSection';
import TrustUsSection from './components/TrustUsSection';
import StatsAndOpportunities from './components/StatsAndOpportunities';
import { useTranslation } from 'react-i18next';
import 'leaflet/dist/leaflet.css';

// Import MSAL for authentication
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import msalConfig from './authConfig'; // Import your MSAL config

// Create an instance of PublicClientApplication
const msalInstance = new PublicClientApplication(msalConfig);

function App() {
  const [currentView, setCurrentView] = useState('home');
  const [searchFilters, setSearchFilters] = useState({});
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n]);

  const handleShowSearch = (category = '') => {
    setSearchFilters(prevFilters => ({
      ...prevFilters,
      category: category
    }));
    setCurrentView('search');
  };

  const handleOneTimeOrOngoingChoice = (choice) => {
    setSearchFilters(prevFilters => ({
      ...prevFilters,
      taskType: choice
    }));
    setCurrentView('search');
  };

  const handleFamilyFriendlyChoice = (isFamilyFriendly) => {
    setSearchFilters(prevFilters => ({
      ...prevFilters,
      familyFriendly: isFamilyFriendly
    }));
    setCurrentView('search');
  };

  const handleShowSuggestedTasks = () => setCurrentView('suggested');
  const handleShowActiveTasks = () => setCurrentView('active');
  const handleShowHome = () => setCurrentView('home');

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('language', lng);
  };

  return (
    <LoadScript googleMapsApiKey="AIzaSyBkKIP65p8pJoO2oijayk9pJ_ofZ8mDHIs">
      <MsalProvider instance={msalInstance}>
        <CMSProvider>
          <UserProvider>
            <div className="min-h-screen bg-[rgb(252_248_241/var(--tw-bg-opacity))] overflow-x-hidden">
              {/* Top bar with Back Button and Language Switcher */}
              <div className="flex justify-between items-center p-4">
                <div>
                  {currentView !== 'home' && (
                    <button
                      onClick={handleShowHome}
                      className="p-2 rounded-full hover:bg-gray-200 transition-colors"
                      aria-label={t('back')}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                      </svg>
                    </button>
                  )}
                </div>
                <div className="flex space-x-4">
                  <button onClick={() => changeLanguage('en')} className="hover:underline">English</button>
                  <button onClick={() => changeLanguage('fr')} className="hover:underline">Français</button>
                </div>
              </div>

              {/* Header Section */}
              <Header
                onShowSearch={handleShowSearch}
                onShowSuggestedTasks={handleShowSuggestedTasks}
                onShowActiveTasks={handleShowActiveTasks}
                onShowHome={handleShowHome}
              />

              {/* Conditional Views */}
              {currentView === 'home' && (
                <>
                  <div className="py-2">
                    <HeroSection onShowSearch={handleShowSearch} />
                  </div>
                  <div className="py-2">
                    <OneTimeOrOngoingChoice onChoose={handleOneTimeOrOngoingChoice} />
                  </div>
                  <div className="py-2">
                    <FamilyFriendlyChoice onChoose={handleFamilyFriendlyChoice} />
                  </div>
                  <div className="py-2">
                    <Categories onShowSearch={handleShowSearch} />
                  </div>
                  <div className="py-2">
                    <StatsAndOpportunities onShowSearch={handleShowSearch}/>
                  </div>
                  <div className="py-2">
                    <TrustUsSection />
                  </div>
                  <div className="py-2">
                    <TestimonialsSection />
                  </div>
                  <div className="py-2">
                    <FAQSection />
                  </div>
                </>
              )}
              {currentView === 'search' && (
                <div className="py-2">
                  <SearchTasks initialFilters={searchFilters} />
                </div>
              )}
              {currentView === 'suggested' && (
                <div className="py-2">
                  <SuggestedTasks />
                </div>
              )}
              {currentView === 'active' && (
                <div className="py-2">
                  <ActiveTasks />
                </div>
              )}

              {/* Footer Section */}
              <Footer />
            </div>
          </UserProvider>
        </CMSProvider>
      </MsalProvider>
    </LoadScript>
  );
}

export default App;