import React, { useState, useEffect } from 'react';
import TaskModal from './TaskModal';
import { useTranslation } from 'react-i18next'; // Add translation hook

const StatsAndOpportunities = ({ onShowSearch }) => {
  const [tasks, setTasks] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);
  const [error, setError] = useState(null);
  const [stats, setStats] = useState({
    active_org_count: 0,
    active_tasks_count: 0,
  });
  const { t } = useTranslation(); // Add translation hook

  const fetchStats = async () => {
    try {
      const response = await fetch('https://joyfromgivingapi-d3hkgkftbth7bcgd.westeurope-01.azurewebsites.net/organsiations/org/dashboard/stats');
      if (!response.ok) {
        throw new Error(t('errors.fetch_stats_failed'));
      }
      const data = await response.json();
      setStats({
        active_org_count: data.active_org_count,
        active_tasks_count: data.active_tasks_count,
      });
    } catch (err) {
      setError(t('errors.failed_to_load_stats'));
    }
  };

  const fetchTasks = async () => {
    try {
      const response = await fetch('https://joyfromgivingapi-d3hkgkftbth7bcgd.westeurope-01.azurewebsites.net/tasks/tasks');
      if (!response.ok) {
        throw new Error(t('errors.fetch_tasks_failed'));
      }
      const data = await response.json();
      setTasks(data.slice(0, 3));
    } catch (err) {
      setError(t('errors.failed_to_load_tasks'));
    }
  };

  const refreshTasks = async () => {
    await fetchTasks();
  };

  useEffect(() => {
    fetchStats();
    fetchTasks();
  }, []);

  const handleLearnMore = (task) => {
    setSelectedTask(task);
  };

  const handleCloseModal = () => {
    setSelectedTask(null);
  };

  return (
    <div className="py-10 sm:py-16">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-12">
          <div className="bg-[#FFF7D4] rounded-lg p-6 text-center shadow-md">
            <span className="text-4xl font-bold block">{stats.active_org_count}</span>
            <span className="text-sm uppercase">{t('stats.organizations')}</span>
          </div>
          <div className="bg-[#FFF7D4] rounded-lg p-6 text-center shadow-md">
            <span className="text-4xl font-bold block">{stats.active_tasks_count}</span>
            <span className="text-sm uppercase">{t('stats.activities_to_choose')}</span>
          </div>
        </div>

        {/* <h2 className="text-3xl font-bold mb-4 text-center">{t('stats.volunteer_a_day')}</h2> */}

        <div className="text-center mb-8">
          <button
            className="bg-[#FEE372] text-black px-8 py-3 rounded-full hover:bg-[#FEE372] transition-colors duration-200"
            onClick={() => onShowSearch()}
          >
            {t('stats.find_opportunities')}
          </button>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {error ? (
            <p className="text-red-500 text-center">{error}</p>
          ) : (
            tasks.map((task) => (
              <div key={task.id} className="bg-blue-50 rounded-lg overflow-hidden shadow-lg">
                <img src={task.thumbnail_url || '/default-thumbnail.jpg'} alt={task.title} className="w-full h-48 object-cover" />
                <div className="p-6">
                  <span className="bg-[#FEE372] text-sm px-3 py-1 rounded-full block mb-2">
                    {task.tags[0] || t('stats.general')}
                  </span>
                  <span className="text-sm text-gray-600 block">
                    {task.is_one_time ? t('stats.one_time') : t('stats.ongoing')}
                  </span>
                  <span className="text-sm text-gray-600 block">
                    {task.is_remote ? t('stats.remote') : t('stats.on_site')}
                  </span>
                  <br />
                  <h3 className="text-2xl font-bold mb-2 text-gray-800">{task.title}</h3>
                  <p className="text-sm text-gray-500 mb-2">{task.is_remote ? t('stats.remote') : task.location}</p>
                  <p className="text-sm mb-4 text-gray-600">{task.description}</p>
                  <button
                    className="mt-4 bg-[#FEE372] text-black px-4 py-2 rounded-full hover:bg-[#FEE372] transition-colors duration-200"
                    onClick={() => handleLearnMore(task)}
                  >
                    {t('stats.learn_more')}
                  </button>
                </div>
              </div>
            ))
          )}
        </div>

        {selectedTask && <TaskModal task={selectedTask} onClose={handleCloseModal} refreshTasks={refreshTasks} />}
      </div>
    </div>
  );
};

export default StatsAndOpportunities;
