import React from 'react';
import { useCMS } from '../contexts/CMSContext';
import { useTranslation } from 'react-i18next';

const SOCIAL_PLATFORMS = [
  'Facebook', 'Twitter', 'Instagram', 'LinkedIn', 'YouTube',
  'Pinterest', 'TikTok', 'Snapchat', 'Reddit', 'Tumblr'
];

const Footer = () => {
  const cmsContent = useCMS();
  const { t } = useTranslation();

  const handleEmailClick = (email) => {
    window.location.href = `mailto:${email}`;
  };

  const socialIcons = {
    Facebook: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor"><path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path></svg>,
    Twitter: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor"><path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path></svg>,
    Instagram: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor"><rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect><path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37z"></path><line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line></svg>,
    LinkedIn: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor"><path d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"></path><circle cx="4" cy="4" r="2"></circle></svg>,
    YouTube: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor"><path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"></path></svg>,
    Pinterest: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor"><path d="M12.017 0C5.396 0 .029 5.367.029 11.987c0 5.079 3.158 9.417 7.618 11.162-.105-.949-.199-2.403.041-3.439.219-.937 1.406-5.957 1.406-5.957s-.359-.72-.359-1.781c0-1.663.967-2.911 2.168-2.911 1.024 0 1.518.769 1.518 1.688 0 1.029-.653 2.567-.992 3.992-.285 1.193.6 2.165 1.775 2.165 2.128 0 3.768-2.245 3.768-5.487 0-2.861-2.063-4.869-5.008-4.869-3.41 0-5.409 2.562-5.409 5.199 0 1.033.394 2.143.889 2.741.099.12.112.225.085.345-.09.375-.293 1.199-.334 1.363-.053.225-.172.271-.401.165-1.495-.69-2.433-2.878-2.433-4.646 0-3.776 2.748-7.252 7.92-7.252 4.158 0 7.392 2.967 7.392 6.923 0 4.135-2.607 7.462-6.233 7.462-1.214 0-2.354-.629-2.758-1.379l-.749 2.848c-.269 1.045-1.004 2.352-1.498 3.146 1.123.345 2.306.535 3.55.535 6.607 0 11.985-5.365 11.985-11.987C23.97 5.39 18.592.026 11.985.026L12.017 0z"></path></svg>,
    TikTok: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor"><path d="M12.525.02c1.31-.02 2.61-.01 3.91-.02.08 1.53.63 3.09 1.75 4.17 1.12 1.11 2.7 1.62 4.24 1.79v4.03c-1.44-.05-2.89-.35-4.2-.97-.57-.26-1.1-.59-1.62-.93-.01 2.92.01 5.84-.02 8.75-.08 1.4-.54 2.79-1.35 3.94-1.31 1.92-3.58 3.17-5.91 3.21-1.43.08-2.86-.31-4.08-1.03-2.02-1.19-3.44-3.37-3.65-5.71-.02-.5-.03-1-.01-1.49.18-1.9 1.12-3.72 2.58-4.96 1.66-1.44 3.98-2.13 6.15-1.72.02 1.48-.04 2.96-.04 4.44-.99-.32-2.15-.23-3.02.37-.63.41-1.11 1.04-1.36 1.75-.21.51-.15 1.07-.14 1.61.24 1.64 1.82 3.02 3.5 2.87 1.12-.01 2.19-.66 2.77-1.61.19-.33.4-.67.41-1.06.1-1.79.06-3.57.07-5.36.01-4.03-.01-8.05.02-12.07z"></path></svg>,
    Snapchat: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor"><path d="M5.829 4.533c-.6 1.344-.363 3.752-.267 5.436-.648.359-1.48-.271-1.951-.271-.49 0-1.075.322-1.167.802-.066.346.089.85 1.201 1.289.43.17 1.453.37 1.69.928.333.784-1.71 4.403-4.918 4.931-.251.041-.43.265-.416.519.056.975 2.242 1.357 3.211 1.507.099.134.179.7.306 1.131.057.193.204.424.582.424.493 0 1.312-.38 2.738-.144 1.398.233 2.712 2.215 5.235 2.215 2.345 0 3.744-1.991 5.09-2.215.779-.129 1.448-.088 2.196.058.515.101.977.157 1.124-.349.129-.437.208-.992.305-1.123.96-.149 3.156-.53 3.211-1.505.014-.254-.165-.477-.416-.519-3.154-.52-5.259-4.128-4.918-4.931.236-.557 1.252-.755 1.69-.928.814-.321 1.222-.716 1.213-1.173-.011-.585-.715-.934-1.233-.934-.527 0-1.284.624-1.897.286.096-1.698.332-4.095-.267-5.438-1.135-2.543-3.66-3.829-6.184-3.829-2.508 0-5.014 1.268-6.158 3.833z"></path></svg>,
    Reddit: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor"><path d="M24 11.779c0-1.459-1.192-2.645-2.657-2.645-.715 0-1.363.286-1.84.746-1.81-1.191-4.259-1.949-6.971-2.046l1.483-4.669 4.016.941-.006.058c0 1.193.975 2.163 2.174 2.163 1.198 0 2.172-.97 2.172-2.163s-.975-2.164-2.172-2.164c-.92 0-1.704.574-2.021 1.379l-4.329-1.015c-.189-.046-.381.063-.44.249l-1.654 5.207c-2.838.034-5.409.798-7.3 2.025-.474-.438-1.103-.712-1.799-.712-1.465 0-2.656 1.187-2.656 2.646 0 .97.533 1.811 1.317 2.271-.052.282-.086.567-.086.857 0 3.911 4.808 7.093 10.719 7.093s10.72-3.182 10.72-7.093c0-.274-.029-.544-.075-.81.832-.447 1.405-1.312 1.405-2.318zm-17.224 1.816c0-.868.71-1.575 1.582-1.575.872 0 1.581.707 1.581 1.575s-.709 1.574-1.581 1.574-1.582-.706-1.582-1.574zm9.061 4.669c-.797.793-2.048 1.179-3.824 1.179l-.013-.003-.013.003c-1.777 0-3.028-.386-3.824-1.179-.145-.144-.145-.379 0-.523.145-.145.381-.145.526 0 .65.647 1.729.961 3.298.961l.013.003.013-.003c1.569 0 2.648-.315 3.298-.962.145-.145.381-.144.526 0 .145.145.145.379 0 .524zm-.189-3.095c-.872 0-1.581-.706-1.581-1.574 0-.868.709-1.575 1.581-1.575s1.581.707 1.581 1.575-.709 1.574-1.581 1.574z"></path></svg>,
    Tumblr: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor"><path d="M14.563 24c-5.093 0-7.031-3.756-7.031-6.411V9.747H5.116V6.648c3.63-1.313 4.512-4.596 4.71-6.469C9.84.051 9.941 0 9.999 0h3.517v6.114h4.801v3.633h-4.82v7.47c.016 1.001.375 2.371 2.207 2.371h.09c.631-.02 1.486-.205 1.936-.419l1.156 3.425c-.436.636-2.4 1.374-4.156 1.404h-.178l.011.002z"></path></svg>
  };

  const renderTextWithClickableEmail = (text) => {
    const emailRegex = /([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/g;
    const parts = text.split(emailRegex);
    
    return parts.map((part, index) => {
      if (part.match(emailRegex)) {
        return (
          <button
            key={index}
            onClick={() => handleEmailClick(part)}
            className="text-blue-600 hover:text-yellow-500 transition-colors duration-300 underline"
          >
            {part}
          </button>
        );
      }
      return part;
    });
  };

  const missionText = cmsContent.missionText || t('footer.mission_text', 'We are a non-profit organization connecting volunteers with meaningful missions. Join us in making a difference.');
  const missionLines = missionText.split('\n');

  return (
    <footer className="py-8" style={{ backgroundColor: '#D4E5FB' }}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
          {/* Column 1: About Section */}
          <div>
            <h4 className="text-xl font-semibold mb-4 text-gray-800">
              {t('footer.about_us', 'About Us')}
            </h4>
            <div className="text-sm text-gray-600">
              {missionLines.map((line, index) => (
                <React.Fragment key={index}>
                  {renderTextWithClickableEmail(line)}
                  {index < missionLines.length - 1 && <br />}
                </React.Fragment>
              ))}
            </div>
          </div>

          {/* Column 2: Social Media */}
          <div>
            <h4 className="text-xl font-semibold mb-4 text-gray-800">
              {t('footer.follow_us', 'Follow Us')}
            </h4>
            <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
              {cmsContent.socialLinks && cmsContent.socialLinks.length > 0 ? (
                cmsContent.socialLinks.map((link, index) => {
                  return (
                    <a
                      key={index}
                      href={link.url}
                      className="flex items-center text-gray-600 hover:text-yellow-500 transition-colors duration-300"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {socialIcons[link.platform] || <span className="w-6 h-6 mr-2" />}
                      <span className="ml-2">{link.platform}</span>
                    </a>
                  );
                })
              ) : (
                SOCIAL_PLATFORMS.map((platform, index) => {
                  return (
                    <a
                      key={index}
                      href="#"
                      className="flex items-center text-gray-600 hover:text-yellow-500 transition-colors duration-300"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {socialIcons[platform] || <span className="w-6 h-6 mr-2" />}
                      <span className="ml-2">{platform}</span>
                    </a>
                  );
                })
              )}
            </div>
          </div>
        </div>

        {/* Legal Documents Section */}
        <div className="border-t border-gray-300 pt-4 mb-4">
          <nav className="flex flex-wrap justify-center items-center space-x-4 text-sm text-gray-600">
            {cmsContent.legalDocuments && cmsContent.legalDocuments.map((doc, index) => (
              <React.Fragment key={index}>
                {index > 0 && <span className="hidden sm:inline">|</span>}
                <a 
                  href={doc.pdf_url} 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="hover:text-gray-800 transition-colors duration-200"
                >
                  {doc.title}
                </a>
              </React.Fragment>
            ))}
          </nav>
        </div>

        {/* Footer Bottom: Copyright and Policies */}
        <div className="text-center text-sm text-gray-600">
          <p>&copy; {new Date().getFullYear()} {t('footer.copyright', 'JoyFromGiving. All rights reserved.')}</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
