import React, { useState } from 'react';
import { useCMS } from '../contexts/CMSContext';
import { useTranslation } from 'react-i18next';

const TestimonialsSection = () => {
  const { testimonials } = useCMS();
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(0);

  // Function to extract video ID and platform from URL
  const getVideoInfo = (url) => {
    if (url.includes('youtu.be') || url.includes('youtube.com')) {
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|shorts\/)([^#&?]*).*/;
      const match = url.match(regExp);
      return { platform: 'youtube', id: (match && match[2].length === 11) ? match[2] : null };
    } else if (url.includes('vimeo.com')) {
      const regExp = /vimeo\.com\/([0-9]+)/;
      const match = url.match(regExp);
      return { platform: 'vimeo', id: match ? match[1] : null };
    }
    return { platform: null, id: null };
  };

  // Function to generate embed URL
  const getEmbedUrl = (videoInfo) => {
    switch (videoInfo.platform) {
      case 'youtube':
        return `https://www.youtube.com/embed/${videoInfo.id}`;
      case 'vimeo':
        return `https://player.vimeo.com/video/${videoInfo.id}`;
      default:
        return '';
    }
  };

  const testimonialsPerPage = {
    mobile: 2,
    desktop: 4
  };

  const totalPages = Math.ceil(testimonials.length / testimonialsPerPage.desktop);

  const handlePrevPage = () => {
    setCurrentPage((prev) => (prev > 0 ? prev - 1 : totalPages - 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => (prev < totalPages - 1 ? prev + 1 : 0));
  };

  const renderTestimonial = (testimonial, index) => {
    const videoInfo = getVideoInfo(testimonial.video_url);
    const embedUrl = getEmbedUrl(videoInfo);
    return (
      <div key={index} className="bg-white rounded-lg overflow-hidden shadow-md">
        <div className="relative pb-[56.25%] h-0">
          {embedUrl && (
            <iframe
              src={embedUrl}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title={t('testimonials.videoTitle', { name: testimonial.name })}
              className="absolute top-0 left-0 w-full h-full"
            ></iframe>
          )}
        </div>
        <div className="p-2 bg-white">
          <h3 className="text-lg font-semibold text-center truncate">{testimonial.name}</h3>
        </div>
      </div>
    );
  };

  return (
    <div className="bg-[#FFF7D4] py-10 sm:py-16">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="rounded-lg bg-[#FFECB3] p-4 sm:p-6 mb-8">
          <h2 className="text-xl sm:text-2xl md:text-3xl font-bold text-gray-800 flex items-center justify-center flex-wrap sm:flex-nowrap">
            <span className="text-center">{t('testimonials.title')}</span>
            <span className="ml-1 sm:ml-2" role="img" aria-label="Smile">😊</span>
          </h2>
        </div>

        {/* Mobile view */}
        <div className="sm:hidden">
          <div className="space-y-4">
            {testimonials.slice(currentPage * testimonialsPerPage.mobile, (currentPage + 1) * testimonialsPerPage.mobile).map(renderTestimonial)}
          </div>
          <div className="flex justify-between items-center mt-6">
            <button
              onClick={handlePrevPage}
              className="text-4xl w-12 h-12 flex items-center justify-center transition-colors duration-200"
            >
              <span role="img" aria-label="Previous">⬅️</span>
            </button>
            <span className="text-lg font-semibold">{t('testimonials.more_videos')}</span>
            <button
              onClick={handleNextPage}
              className="text-4xl w-12 h-12 flex items-center justify-center transition-colors duration-200"
            >
              <span role="img" aria-label="Next">➡️</span>
            </button>
          </div>
        </div>

        {/* Desktop view */}
        <div className="hidden sm:block">
          <div className="grid grid-cols-2 lg:grid-cols-4 gap-4">
            {testimonials.slice(currentPage * testimonialsPerPage.desktop, (currentPage + 1) * testimonialsPerPage.desktop).map(renderTestimonial)}
          </div>
          <div className="flex justify-between items-center mt-6">
            <button
              onClick={handlePrevPage}
              className="text-4xl w-12 h-12 flex items-center justify-center transition-colors duration-200"
            >
              <span role="img" aria-label="Previous">⬅️</span>
            </button>
            <button
              onClick={handleNextPage}
              className="text-4xl w-12 h-12 flex items-center justify-center transition-colors duration-200"
            >
              <span role="img" aria-label="Next">➡️</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialsSection;