import React from 'react';
import { useTranslation } from 'react-i18next';

const FamilyFriendlyChoice = ({ onChoose }) => {
  const { t } = useTranslation();

  return (
    <div className="bg-[#FFF7D4] py-10 sm:py-16">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <button
          onClick={() => onChoose('Yes')}  // Changed from true to 'Yes'
          className="w-full bg-white rounded-lg p-6 text-left shadow-lg hover:shadow-xl transition-shadow duration-200 border border-transparent hover:border-yellow-300"
        >
          <div className="flex items-center justify-between">
            <div>
              <span className="text-2xl font-bold mr-2" role="img" aria-label="Family">👨‍👩‍👧‍👦</span>
              <span className="text-xl font-semibold">{t('family_friendly.help_with_family', 'Help with my family')}</span>
            </div>
            <span className="text-3xl" role="img" aria-label="Right arrow">➡️</span>
          </div>
        </button>
      </div>
    </div>
  );
};

export default FamilyFriendlyChoice;
