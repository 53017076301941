import React, { useState } from 'react';
import { useCMS } from '../contexts/CMSContext';

const FAQSection = () => {
  const [openQuestion, setOpenQuestion] = useState(null);
  const cmsContent = useCMS();

  const toggleQuestion = (index) => {
    setOpenQuestion(openQuestion === index ? null : index);
  };

  // Use CMS FAQs if available, otherwise use fallback FAQs
  const faqs = cmsContent.faqs && cmsContent.faqs.length > 0
    ? cmsContent.faqs
    : [
      {
        question: 'Who can register on JeVeuxAider.gouv.fr?',
        answer: 'Anyone who wants to volunteer and support non-profit organizations can register.'
      },
      {
        question: 'How to find a mission?',
        answer: 'You can search by location, category, or specific keywords to find a mission.'
      },
      {
        question: 'How to apply for a mission?',
        answer: 'Click on the blue button on the right of the "I propose my help" screen to apply.'
      }
    ];

  return (
    <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
      <div className="text-center mb-12">
        <div className="inline-flex items-center justify-center p-2 rounded-full mb-4" style={{ backgroundColor: '#FFECB3' }}>
          <span className="text-3xl">❓</span>
        </div>
        <h2 className="text-3xl font-bold">Still have Questions?</h2>
      </div>
      {/* FAQ List */}
      <div className="space-y-4">
        {faqs.map((faq, index) => (
          <div key={index} className="border border-gray-200 rounded-lg overflow-hidden">
            <div
              className="flex justify-between items-center bg-gray-100 px-4 py-3 cursor-pointer transition-colors duration-300 hover:bg-gray-200"
              onClick={() => toggleQuestion(index)}
            >
              <h3 className="font-semibold">{faq.question}</h3>
              <span className="text-xl">{openQuestion === index ? '−' : '+'}</span>
            </div>
            {openQuestion === index && (
              <div className="bg-white px-4 py-3 text-gray-600 transition-all duration-300 ease-in-out">
                {faq.answer}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQSection;