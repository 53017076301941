import React, { createContext, useState, useContext, useEffect, useRef } from 'react';

const CMSContext = createContext();

export const CMSProvider = ({ children }) => {
  const [cmsContent, setCmsContent] = useState({
    title: '',
    description: '',
    imageUrl: '',
    missionText: '',
    interestedCategories: [],
    faqs: [],
    socialLinks: [],
    legalDocuments: [],
    testimonials: []
  });

  const apiUrl = 'https://joyfromgivingapi-d3hkgkftbth7bcgd.westeurope-01.azurewebsites.net';
  const hasFetchedCmsContent = useRef(false);

  useEffect(() => {
    const fetchCmsContent = async () => {
      try {
        const response = await fetch(`${apiUrl}/cms/v2`);
        if (response.ok) {
          const data = await response.json();
          console.log(data);
          setCmsContent({
            missionText: data.mission_text || '',
            interestedCategories: data.interested_categories || [],
            faqs: data.faqs || [],
            socialLinks: data.social_links || [],
            legalDocuments: data.legal_documents || [],
            testimonials: data.testimonials || []
          });
        } else {
          console.error('Failed to fetch CMS content');
        }
      } catch (error) {
        console.error('Error fetching CMS content', error);
      }
    };

    if (!hasFetchedCmsContent.current) {
      fetchCmsContent();
      hasFetchedCmsContent.current = true;
    }
  }, []);

  return (
    <CMSContext.Provider value={cmsContent}>
      {children}
    </CMSContext.Provider>
  );
};

export const useCMS = () => useContext(CMSContext);
export default CMSContext;